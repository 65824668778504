import { tagApi } from "@/tags/api.ts";
import { call, fork, put } from "redux-saga/effects";
import { Tag } from "@/tags/types.ts";
import { setTags } from "@/tags/tagsSlice.ts";
import localforage from "localforage";

export function* fetchAllTags() {
  const tags: Tag[] = yield call(tagApi.fetchAllTags);
  yield put(setTags(tags));
  yield call([localforage, localforage.setItem], "tags", tags);
}

function* loadFromLocalForage() {
  const tags: Tag[] = yield call([localforage, localforage.getItem], "tags");
  if (tags) {
    yield put(setTags(tags));
  }
}

export default function* tagsSaga() {
  console.log("tagsSaga");
  yield fork(loadFromLocalForage);
  yield fork(fetchAllTags);
}
