import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import UploadPictures from "./UploadPictures.tsx";
import { API_BASE_URL } from "../config.ts";
import { NewPictureFormData } from "./types.ts";
import { getToken } from "../supabase.ts";
import { Button } from "@/components/ui/button.tsx";

interface UploadPictureResponse {
  id: string;
}

const retryFetch = async (
  url: string,
  options: RequestInit,
  retries: number = 3,
): Promise<Response> => {
  for (let i = 0; i < retries; i++) {
    const response = await fetch(url, options);
    if (response.ok) {
      return response;
    }
    if (i < retries - 1) {
      await new Promise((res) => setTimeout(res, 1000)); // wait 1 second before retrying
    }
  }
  throw new Error("Error uploading picture after multiple attempts");
};

const uploadPicture = async (file: File): Promise<UploadPictureResponse> => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("jwt", getToken());

  const response = await retryFetch(API_BASE_URL + "/upload-picture/", {
    method: "POST",
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
    body: formData,
  });

  return await response.json();
};

export default function NewPicture() {
  const { id: boxId } = useParams();

  const defaultValues = {
    name: "",
    boxId: boxId,
    pictures: [],
  };

  const { control, handleSubmit, reset } = useForm<NewPictureFormData>({
    defaultValues,
  });

  const navigate = useNavigate();

  const onSubmit = async (data: NewPictureFormData) => {
    const pictureUploadPromises = data.pictures.map((picture) =>
      // todo do something with the response
      uploadPicture(picture.file),
    );
    await Promise.all(pictureUploadPromises);
  };

  const handleSave = () => {
    handleSubmit(onSubmit)();
    navigate("/pictures");
  };

  const handleSaveAddMore = () => {
    handleSubmit(onSubmit)();
    reset(defaultValues);
  };

  return (
    <>
      <h1 className={"text-3xl font-bold underline italic"}>New Pictures</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <UploadPictures control={control} />

        <div className={"mt-2"}>
          <Button onClick={handleSave}>Save</Button>
        </div>
        <div className={"mt-2"}>
          <Button onClick={handleSaveAddMore}>Save and Add More</Button>
        </div>
      </form>
    </>
  );
}
