import { Card, CardContent } from "@/components/ui/card.tsx";
import { Button } from "@/components/ui/button.tsx";
import { useDispatch, useSelector } from "react-redux";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

import { RootState } from "@/store.ts";
import { Picture } from "@/pictures/types.ts";
import {
  deletePictureRequest,
  selectPicture,
} from "@/pictures/picturesSlice.ts";
import CreateItemButton from "@/pictures/components/CreateItemButton.tsx";
import React, { useState } from "react";
import CreateBoxButton from "@/pictures/components/CreateBoxButton.tsx";
import AttachItemButton from "@/pictures/components/AttachItemButton.tsx";
import AttachBoxButton from "@/pictures/components/AttachBoxButton.tsx";
import ConfirmDelete from "@/components/ConfirmDelete.tsx";
import { EllipsisVertical } from "lucide-react";

interface UnattachedPicturePropTypes {
  pictureId: string;
}

const UnattachedPicture: React.FC<UnattachedPicturePropTypes> = ({
  pictureId,
}) => {
  const dispatch = useDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleDelete = () => {
    dispatch(deletePictureRequest(pictureId));
    setShowDeleteModal(false);
  };

  const picture = useSelector<RootState, Picture | null>((state) =>
    selectPicture(state, pictureId),
  );

  if (!picture) {
    return null;
  }

  return (
    <Card>
      <div className="flex justify-between items-center p-4">
        <h2 className="text-xl font-semibold">Picture Details</h2>
        <DropdownMenu>
          <DropdownMenuTrigger>
            <Button>
              <EllipsisVertical />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <DropdownMenuItem onClick={() => setShowDeleteModal(true)}>
              Delete
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
        <ConfirmDelete
          isOpen={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          onConfirm={handleDelete}
          itemName={"picture"}
        />
      </div>
      <CardContent className={"pt-6"}>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <div>
            <img
              src={picture.publicUrl}
              className={
                "w-full h-auto max-h-96 object-contain mb-4 rounded-t-lg"
              }
            />
          </div>
          <div>
            <p>{picture.description}</p>
            <div className="mt-2 grid grid-cols-2 gap-4">
              <CreateItemButton pictureId={pictureId} />
              <CreateBoxButton pictureId={pictureId} />
              <AttachItemButton pictureId={pictureId} />
              <AttachBoxButton pictureId={pictureId} />
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default UnattachedPicture;
