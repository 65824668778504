import { map } from "lodash";
import { Tag } from "@/tags/types.ts";
import { api } from "@/api.ts";
import { Picture } from "@/pictures/types.ts";

export const itemsPerPage = 12;

export interface ApiPicture {
  id: string;
  name: string;
  description: string;
  public_url: string;
  upload_date: string;
  ai_description: string;
  tags: Tag[];
  item_id: string;
  box_id: string;
}

export function mapPicture(picture: ApiPicture): Picture {
  return {
    id: picture.id,
    name: picture.name,
    description: picture.description,
    publicUrl: picture.public_url,
    uploadDate: picture.upload_date,
    aiDescription: picture.ai_description,
    tags: picture.tags,
    itemId: picture.item_id,
    boxId: picture.box_id,
  };
}

export function unmapPicture(picture: Picture): ApiPicture {
  return {
    id: picture.id,
    name: picture.name,
    description: picture.description,
    public_url: picture.publicUrl,
    upload_date: picture.uploadDate,
    ai_description: picture.aiDescription,
    tags: picture.tags,
    item_id: picture.itemId,
    box_id: picture.boxId,
  };
}

export function unmapPartialPicture(
  picture: Partial<Picture>,
): Partial<ApiPicture> {
  const apiPicture: Partial<ApiPicture> = {};
  if (picture.id !== undefined) apiPicture.id = picture.id;
  if (picture.name !== undefined) apiPicture.name = picture.name;
  if (picture.description !== undefined)
    apiPicture.description = picture.description;
  if (picture.publicUrl !== undefined)
    apiPicture.public_url = picture.publicUrl;
  if (picture.uploadDate !== undefined)
    apiPicture.upload_date = picture.uploadDate;
  if (picture.aiDescription !== undefined)
    apiPicture.ai_description = picture.aiDescription;
  if (picture.tags !== undefined) apiPicture.tags = picture.tags;
  if (picture.itemId !== undefined) apiPicture.item_id = picture.itemId;
  if (picture.boxId !== undefined) apiPicture.box_id = picture.boxId;
  return apiPicture;
}

interface FetchPicturesApiResponse {
  total: number;
  pictures: ApiPicture[];
}

export interface FetchPicturesResponse {
  total: number;
  pictures: Picture[];
}

export const pictureApi = {
  fetchAllPictures: async (): Promise<Picture[]> => {
    const response = await api.get<ApiPicture[]>("/pictures/all");
    return map(response, mapPicture);
  },
  fetchPictures: async (page: number): Promise<FetchPicturesResponse> => {
    const skip = page * itemsPerPage - itemsPerPage;
    const response = await api.get<FetchPicturesApiResponse>("/pictures", {
      params: { limit: itemsPerPage.toString(), skip: skip.toString() },
    });
    return {
      total: response.total,
      pictures: map(response.pictures, mapPicture),
    };
  },

  fetchPictureById: async (id: string): Promise<Picture> => {
    const response = await api.get<ApiPicture>(`/pictures/${id}`);
    return mapPicture(response);
  },

  fetchPicturesByTags: async (tags: string[]) => {
    const response = await api.get<FetchPicturesApiResponse>("/pictures", {
      params: { tags: tags.join(",") },
    });
    return {
      total: response.total,
      pictures: map(response.pictures, mapPicture),
    };
  },

  updatePicture: async (
    picture: Partial<Picture> & { id: string },
  ): Promise<Picture> => {
    const response = await api.patch<ApiPicture>(
      `/pictures/${picture.id}`,
      unmapPartialPicture(picture),
    );
    return mapPicture(response);
  },

  deletePicture: async (id: string): Promise<void> => {
    await api.delete(`/pictures/${id}`);
  },

  describePicture: async (id: string): Promise<Picture> => {
    const response = await api.post<ApiPicture>(`/pictures/${id}/describe`, {});
    return mapPicture(response);
  },
};
