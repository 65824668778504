import { call, fork, put, takeEvery } from "redux-saga/effects";
import {
  addItem,
  fetchItem,
  removeItem,
  setItem,
  setItems,
  updateItem,
  createItemRequest,
  setLoading,
  setError,
  updateItemRequest,
  addItemToBoxRequest,
} from "./itemsSlice.ts";
import { Item } from "./types.ts";
import { PayloadAction } from "@reduxjs/toolkit";
import router from "@/routes.tsx";
import { itemApi, ItemUpdate } from "@/items/api.ts";
import localforage from "localforage";
import * as Sentry from "@sentry/browser";
import { handleFetchPictureById } from "@/pictures/picturesSaga.ts";
import { fetchPictureRequest } from "@/pictures/picturesSlice.ts";

function* fetchItems() {
  try {
    yield put(setLoading(true));
    const items: Item[] = yield call(itemApi.fetchItems);
    yield put(setItems(items));
    yield put(setError(null));
  } catch (error) {
    Sentry.captureException(error);
    yield put(setError((error as Error).message));
  } finally {
    yield put(setLoading(false));
  }
}

function* handleFetchItem({ payload: id }: PayloadAction<string>) {
  try {
    yield put(setLoading(true));
    const item: Item = yield call(itemApi.fetchItemById, id);
    yield put(setItem(item));
    yield put(setError(null));
  } catch (error) {
    Sentry.captureException(error);
    yield put(setError((error as Error).message));
  } finally {
    yield put(setLoading(false));
  }
}

function* handleAddItem({ payload: item }: PayloadAction<Item>) {
  try {
    yield put(setLoading(true));
    yield call(itemApi.createItem, item);
    yield call(fetchItems);
    yield put(setError(null));
  } catch (error) {
    Sentry.captureException(error);
    yield put(setError((error as Error).message));
  } finally {
    yield put(setLoading(false));
  }
}

function* handleCreateItemRequest({
  payload,
}: PayloadAction<{ name: string; description: string; pictureId: string }>) {
  try {
    yield put(setLoading(true));
    const newItem: Item = yield call(itemApi.createItem, payload);
    yield call(fetchItems);
    if (payload.pictureId) {
      yield call(
        handleFetchPictureById,
        fetchPictureRequest(payload.pictureId),
      );
    }
    yield put(setError(null));
    // Here you can add navigation logic if needed
    yield call(router.navigate, `/items/${newItem.id}`);
  } catch (error) {
    Sentry.captureException(error);
    yield put(setError((error as Error).message));
  } finally {
    yield put(setLoading(false));
  }
}

function* handleUpdateItem({ payload: item }: PayloadAction<ItemUpdate>) {
  try {
    yield put(setLoading(true));
    const updatedItem: Item = yield call(itemApi.updateItem, item);
    yield put(setItem(updatedItem));
    if (item.pictureId) {
      yield call(handleFetchPictureById, fetchPictureRequest(item.pictureId));
    }
    yield put(setError(null));
    yield call(router.navigate, `/items/${updatedItem.id}`);
  } catch (error) {
    Sentry.captureException(error);
    yield put(setError((error as Error).message));
  } finally {
    yield put(setLoading(false));
  }
}

function* handleAddItemToBox({ payload: item }: PayloadAction<ItemUpdate>) {
  try {
    yield put(setLoading(true));
    const updatedItem: Item = yield call(itemApi.updateItem, item);
    yield put(setItem(updatedItem));
  } catch (error) {
    Sentry.captureException(error);
    yield put(setError((error as Error).message));
  } finally {
    yield put(setLoading(false));
  }
}

function* handleDeleteItem({ payload: item }: PayloadAction<Item>) {
  try {
    yield put(setLoading(true));
    yield call(itemApi.deleteItem, item.id);
    yield call(fetchItems);
    yield put(setError(null));
  } catch (error) {
    Sentry.captureException(error);
    yield put(setError((error as Error).message));
  } finally {
    yield put(setLoading(false));
  }
}

function* handleFetchAllItems() {
  const items: Item[] = yield call(itemApi.fetchAllItems);
  yield put(setItems(items));
  yield call([localforage, localforage.setItem], "items", items);
}

function* loadFromLocalForage() {
  const items: Item[] = yield call([localforage, localforage.getItem], "items");
  if (items) {
    yield put(setItems(items));
  }
}

export function* itemsSaga() {
  console.log("itemsSaga");
  yield fork(loadFromLocalForage);
  yield fork(handleFetchAllItems);
  yield takeEvery(addItem.type, handleAddItem);
  yield takeEvery(fetchItem.type, handleFetchItem);
  yield takeEvery(updateItem.type, handleUpdateItem);
  yield takeEvery(removeItem.type, handleDeleteItem);
  yield takeEvery(createItemRequest.type, handleCreateItemRequest);
  yield takeEvery(updateItemRequest.type, handleUpdateItem);
  yield takeEvery(addItemToBoxRequest.type, handleAddItemToBox);
}
