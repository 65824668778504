import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Session } from "@supabase/supabase-js";

export interface SessionState {
  session: Session | null;
  sessionLoading: boolean;
  backendAvailable: boolean;
}

const initialState: SessionState = {
  session: null,
  sessionLoading: true,
  backendAvailable: false,
};

const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    setSession(state, action: PayloadAction<Session | null>) {
      state.session = action.payload;
      state.sessionLoading = false;
    },
    setBackendAvailable(state, action: PayloadAction<boolean>) {
      state.backendAvailable = action.payload;
    },
  },
});

const thisSlice = (state: { session: SessionState }) => state.session;

export const isLoggedIn = createSelector(thisSlice, (state) => !!state.session);

export const isLoading = createSelector(
  thisSlice,
  (state) => state.sessionLoading || !state.backendAvailable,
);

export const { setSession, setBackendAvailable } = sessionSlice.actions;

export default sessionSlice.reducer;
