import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import {
  createItemRequest,
  selectError,
  selectLoading,
} from "@/items/itemsSlice.ts";
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog.tsx";

interface CreateItemButtonPropTypes {
  pictureId?: string;
}

const CreateItemButton = ({ pictureId }: CreateItemButtonPropTypes) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const dispatch = useDispatch();
  const loading = useSelector(selectLoading);
  const error = useSelector(selectError);
  const [isOpen, setIsOpen] = useState(false);

  const handleCreateItem = () => {
    const payload = { name, description };
    if (pictureId) {
      Object.assign(payload, { pictureId });
    }
    dispatch(createItemRequest(payload));
  };

  return (
    <>
      <AlertDialog open={isOpen}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>Create New Item</AlertDialogTitle>
            </AlertDialogHeader>
            <AlertDialogDescription className={"space-y-4"}>
              <Input
                placeholder="Item Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <Textarea
                placeholder="Item Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />

              {error && <div className="text-red-500">{error}</div>}
            </AlertDialogDescription>
            <AlertDialogFooter>
              <Button onClick={() => setIsOpen(false)}>Cancel</Button>
              <Button onClick={handleCreateItem} disabled={loading}>
                {loading ? "Creating..." : "Create Item"}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <Button onClick={() => setIsOpen(true)}>Create New Item</Button>
    </>
  );
};

export default CreateItemButton;
