import UnattachedPicture from "@/review-queue/components/UnattachedPicture.tsx";
import { useSelector } from "react-redux";
import { selectUnattachedPictures } from "@/pictures/picturesSlice.ts";

function ReviewQueuePage() {
  const pictures = useSelector(selectUnattachedPictures);
  return (
    <div>
      <h1 className="text-3xl font-semibold tracking-wide mt-6 mb-2">
        Review Queue
      </h1>
      <p className="text-gray-500">
        Here is a list of items, boxes and pictures that need to be reviewed.
      </p>
      <div className="space-y-4">
        {pictures.map((picture) => (
          <UnattachedPicture key={picture.id} pictureId={picture.id} />
        ))}
      </div>
    </div>
  );
}

export default ReviewQueuePage;
