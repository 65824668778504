import { useState } from "react";
import { Search } from "lucide-react";
import TagDisplay from "../pictures/TagDisplay.tsx";
import { Link } from "react-router-dom";
import { Tag } from "@/tags/types.ts";
import { Picture } from "@/pictures/types.ts";
import LoadingSpinner from "@/components/LoadingSpinner.tsx";
import { useSelector } from "react-redux";
import {
  selectLoadingAllTags,
  selectTagsMatchingTerm,
} from "@/tags/tagsSlice.ts";
import { RootState } from "@/store.ts";
import { pictureUrl } from "@/pictures/utils.ts";
import { selectPicturesByTags } from "@/pictures/picturesSlice.ts";

const TagSearchPage = () => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);
  const loadingAllTags = useSelector(selectLoadingAllTags);
  const matchingTags = useSelector<RootState, Tag[]>((state) =>
    selectTagsMatchingTerm(state, searchTerm),
  );

  const pictures = useSelector<RootState, Picture[]>((state) =>
    selectPicturesByTags(
      state,
      selectedTags.map((tag) => tag.id),
    ),
  );

  const handleTagSelect = (tag: Tag) => {
    if (!selectedTags.includes(tag)) {
      setSelectedTags([...selectedTags, tag]);
    }
  };

  const handleTagRemove = (tag_id: string) => {
    setSelectedTags(selectedTags.filter((t) => t.id !== tag_id));
  };

  if (loadingAllTags) {
    return <LoadingSpinner />;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Search Pictures by Tags</h1>

      <div className="mb-6">
        <div className="relative">
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search for tags..."
            className="w-full p-2 pl-10 border rounded-md"
          />
          <Search className="absolute left-3 top-2.5 text-gray-400" size={20} />
        </div>
      </div>

      {matchingTags.length > 0 && searchTerm && (
        <div className="mb-6">
          <h2 className="text-xl font-semibold mb-2">Matching Tags:</h2>
          <div className="flex flex-wrap gap-2">
            {matchingTags.map((tag) => (
              <button
                key={tag.id}
                onClick={() => handleTagSelect(tag)}
                className="px-3 py-1 bg-blue-100 text-blue-800 rounded-full hover:bg-blue-200"
              >
                {tag.name}
              </button>
            ))}
          </div>
        </div>
      )}

      {selectedTags.length > 0 && (
        <div className="mb-6">
          <h2 className="text-xl font-semibold mb-2">Selected Tags:</h2>
          <TagDisplay
            tags={selectedTags}
            onRemove={handleTagRemove}
            editable={true}
          />
        </div>
      )}

      {pictures.length > 0 ? (
        <div>
          <h2 className="text-2xl font-semibold mb-4">Tagged Pictures</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {pictures.map((picture) => {
              return (
                <div
                  key={picture.id}
                  className="border rounded-lg overflow-hidden shadow-md"
                >
                  <img
                    src={pictureUrl(picture)}
                    alt={picture.name}
                    className="w-full h-48 object-cover"
                  />
                  <div className="p-4">
                    <Link to={`/pictures/${picture.id}`}>
                      <h3 className="font-semibold text-lg mb-2">
                        {picture.name}
                      </h3>
                    </Link>
                    <TagDisplay tags={picture.tags} />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        selectedTags.length > 0 && (
          <p className="text-gray-600">
            No pictures found for the selected tags.
          </p>
        )
      )}
    </div>
  );
};

export default TagSearchPage;
