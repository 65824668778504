import { Tag } from "@/tags/types.ts";
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { keyBy } from "lodash";
import { RootState } from "@/store.ts";

export interface TagsState {
  tags: Record<string, Tag>;
  loadingAllTags: boolean;
}

const initialState: TagsState = {
  tags: {},
  loadingAllTags: true,
};

export const tagsSlice = createSlice({
  name: "tags",
  initialState,
  reducers: {
    addTag: (state, action: PayloadAction<Tag>) => {
      state.tags[action.payload.id] = action.payload;
    },
    removeTag: (state, action: PayloadAction<string>) => {
      delete state.tags[action.payload];
    },
    updateTag: (state, action: PayloadAction<Tag>) => {
      state.tags[action.payload.id] = action.payload;
    },
    setTag: (state, action: PayloadAction<Tag>) => {
      state.tags[action.payload.id] = action.payload;
    },
    setTags: (state, action: PayloadAction<Tag[]>) => {
      state.tags = keyBy(action.payload, "id");
      state.loadingAllTags = false;
    },
    fetchAllTagsRequest: (state) => {
      state.loadingAllTags = true;
    },
  },
});

export const { addTag, removeTag, updateTag, setTag, setTags } =
  tagsSlice.actions;

const thisSlice = (state: { tags: TagsState }) => state.tags;

export const selectTags = (state: { tags: TagsState }) => thisSlice(state).tags;

export const selectTag = (state: { tags: TagsState }) => (tagId: string) =>
  thisSlice(state).tags[tagId];

export const selectLoadingAllTags = (state: { tags: TagsState }) =>
  thisSlice(state).loadingAllTags;

export const selectTagsMatchingTerm = createSelector(
  thisSlice,
  (_state: RootState, term: string) => term,
  (state, term) =>
    Object.values(state.tags).filter((tag) =>
      tag.name.toLowerCase().includes(term.toLowerCase()),
    ),
);

export default tagsSlice.reducer;
