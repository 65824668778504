import {
  createAction,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { Item } from "./types";
import { filter, keyBy, pickBy } from "lodash";
import { RootState } from "@/store.ts";
import { ItemUpdate } from "@/items/api.ts";

export interface ItemsState {
  items: Record<string, Item>;
  loading: boolean;
  error: string | null;
  currentPage: number;
  searchTerm: string;
}

// Initial state
const initialState: ItemsState = {
  items: {},
  loading: false,
  error: null,
  currentPage: 1,
  searchTerm: "",
};

// Create the slice
const itemsSlice = createSlice({
  name: "items",
  initialState,
  reducers: {
    addItem: (state, action) => {
      const item: Item = action.payload;
      state.items[item.id] = item;
    },
    setItems: (state, action) => {
      state.items = keyBy(action.payload, "id");
    },
    setItem: (state, action) => {
      const item: Item = action.payload;
      state.items[item.id] = item;
    },
    removeItem: (state, action) => {
      delete state.items[action.payload];
    },
    updateItem: (state, action) => {
      const item: Item = action.payload;
      state.items[item.id] = item;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setSearchTerm: (state, action: PayloadAction<string>) => {
      state.searchTerm = action.payload;
    },
  },
});

export const fetchItem = createAction<string>("items/fetchItem");
export const createItemRequest = createAction<{
  name: string;
  description: string;
  pictureId?: string;
}>("items/createItemRequest");

export const updateItemRequest = createAction<ItemUpdate>(
  "items/updateItemRequest",
);

export const addItemToBoxRequest = createAction<ItemUpdate>(
  "items/addItemToBoxRequest",
);

// Export actions
export const {
  addItem,
  removeItem,
  setItem,
  setItems,
  updateItem,
  setLoading,
  setError,
  setCurrentPage,
  setSearchTerm,
} = itemsSlice.actions;

const thisSlice = (state: { items: ItemsState }) => state.items;
// Export selectors
export const selectAllItems = createSelector(thisSlice, (state) => state.items);
export const selectItemById = createSelector(
  selectAllItems,
  (_state: RootState, id?: string) => id,
  (items, id) => (id ? items[id] : null),
);

export const selectItemsForBox = createSelector(
  selectAllItems,
  (_state: RootState, boxId: string | undefined) => boxId,
  (items, boxId) => filter(items, { boxId: boxId }),
);

export const selectLoading = createSelector(
  thisSlice,
  (state) => state.loading,
);
export const selectError = createSelector(thisSlice, (state) => state.error);

export const selectLoadingError = (state: { items: ItemsState }) => {
  const { loading, error } = thisSlice(state);
  return { loading, error };
};

export const selectFilteredItems = createSelector(
  selectAllItems,
  thisSlice,
  (items, { searchTerm }) => {
    if (!searchTerm) {
      return items;
    }
    return pickBy(
      items,
      (item) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.description.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  },
);

export const selectCurrentPage = (state: RootState) =>
  thisSlice(state).currentPage;

export const selectTotalPages = createSelector(selectFilteredItems, (items) =>
  Math.ceil(Object.keys(items).length / 10),
);

export const selectItemsPage = createSelector(
  selectFilteredItems,
  selectCurrentPage,
  (items, page) => {
    const start = (page - 1) * 10;
    const end = start + 10;
    const itemsArray = Object.values(items);
    return itemsArray.slice(start, end);
  },
);

// Export reducer
export default itemsSlice.reducer;
