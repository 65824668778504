import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Item } from "./types";

import { fetchItem, selectItemById, updateItem } from "./itemsSlice.ts";
import { RootState } from "@/store.ts";
import { SelectBox } from "@/boxes/components/SelectBox.tsx";
import { Box } from "@/boxes/types.ts";
import { Button } from "@/components/ui/button.tsx";

const EditItemPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<Item>();

  const item = useSelector<RootState, Item | null>((state) =>
    selectItemById(state, id),
  );
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (id) {
      dispatch(fetchItem(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (item) {
      setValue("name", item.name);
      setValue("description", item.description);
      setValue("boxId", item.boxId);
    }
  }, [item, setValue]);

  const onSubmit = async (data: Item) => {
    setIsLoading(true);
    try {
      if (!data.boxId) {
        data.boxId = null;
      }
      dispatch(updateItem({ ...data, id }));
    } finally {
      setIsLoading(false);
    }
  };

  if (!item) {
    return <div className="text-center mt-8">Loading...</div>;
  }

  return (
    <div className="max-w-2xl mx-auto mt-8 p-6 bg-white rounded shadow">
      <h1 className="text-2xl font-bold mb-6">Edit Item</h1>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
        <div>
          <label
            htmlFor="name"
            className="block text-sm font-medium text-gray-700"
          >
            Name
          </label>
          <input
            id="name"
            type="text"
            {...register("name", { required: "Name is required" })}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
          {errors.name && (
            <p className="mt-1 text-sm text-red-600">{errors.name.message}</p>
          )}
        </div>
        <div>
          <label
            htmlFor="description"
            className="block text-sm font-medium text-gray-700"
          >
            Description
          </label>
          <textarea
            id="description"
            {...register("description", {
              required: "Description is required",
            })}
            rows={4}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
          {errors.description && (
            <p className="mt-1 text-sm text-red-600">
              {errors.description.message}
            </p>
          )}
        </div>
        <div>
          <label
            htmlFor="box"
            className="block text-sm font-medium text-gray-700"
          >
            Box
          </label>
          <Controller
            name="boxId"
            control={control}
            render={({ field }) => (
              <SelectBox
                selectedId={field.value}
                onSelect={(box: Box) => {
                  field.onChange(box.id);
                }}
              />
            )}
          />
          <Controller
            name={"boxId"}
            control={control}
            render={({ field }) => (
              <Button
                type={"button"}
                className={"ml-2"}
                variant={"outline"}
                onClick={() => {
                  console.log("click");
                  field.onChange("");
                }}
              >
                Reset
              </Button>
            )}
          />
        </div>
        <div className="flex justify-end">
          <button
            type="button"
            onClick={() => navigate("/items")}
            className="mr-4 px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={isLoading}
            className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {isLoading ? "Saving..." : "Save Changes"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditItemPage;
