import { Tag, TagCreate } from "./types";
import { map } from "lodash";
import { api } from "@/api.ts";

export interface ApiTag {
  id: string;
  name: string;
}

export interface PaginatedTagsResponse {
  tags: ApiTag[];
  total: number;
  skip: number;
  limit: number;
}

function mapTag(tag: ApiTag): Tag {
  return {
    id: tag.id,
    name: tag.name,
  };
}

export const tagApi = {
  fetchAllTags: async (): Promise<Tag[]> => {
    const response = await api.get<ApiTag[]>("/tags/all");
    return map(response, mapTag);
  },
  fetchTags: async (
    term: string = "",
    skip: number = 0,
    limit: number = 10,
  ): Promise<PaginatedTagsResponse> => {
    const response = await api.get<PaginatedTagsResponse>("/tags", {
      params: {
        search: term,
        skip: skip.toString(),
        limit: limit.toString(),
      },
    });
    return {
      tags: map(response.tags, mapTag),
      total: response.total,
      skip: response.skip,
      limit: response.limit,
    };
  },

  fetchTagById: async (id: string): Promise<Tag> => {
    const data = await api.get<ApiTag>(`/tags/${id}`);
    return mapTag(data);
  },

  createTag: async (tag: TagCreate): Promise<Tag> => {
    const response = await api.post<ApiTag>("/tags", {
      name: tag.name,
    });
    return mapTag(response);
  },

  updateTag: async (tag: Tag): Promise<Tag> => {
    const response = await api.put<ApiTag>(`/tags/${tag.id}`, tag);
    return mapTag(response);
  },

  deleteTag: (id: string): Promise<string> =>
    api.delete<string>(`/tags/${id}`).then(() => id),
};
