import { useState } from "react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover.tsx";
import { Button, buttonVariants } from "@/components/ui/button.tsx";
import { QRCodeSVG } from "qrcode.react";
import { Box } from "@/boxes/types.ts";
import { useDispatch } from "react-redux";
import { generateBoxCodeRequest } from "@/boxes/boxesSlice.ts";

interface QRCodePopoverProps {
  box: Box;
}

export const QRCodePopover: React.FC<QRCodePopoverProps> = ({ box }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [generating, setGenerating] = useState(false);
  const dispatch = useDispatch();

  if (!box.code) {
    if (generating) {
      return <Button disabled>Generating...</Button>;
    }
    return (
      <Button
        onClick={() => {
          setGenerating(true);
          dispatch(generateBoxCodeRequest(box.id));
        }}
      >
        Generate QR Code
      </Button>
    );
  }

  return (
    <Popover open={isOpen}>
      <PopoverTrigger
        className={buttonVariants()}
        onClick={() => setIsOpen(true)}
      >
        QR Code
      </PopoverTrigger>
      <PopoverContent className={"w-96"}>
        <p className={"my-2"}>Scan this code to view the box</p>
        <div className="p-4">
          <QRCodeSVG
            value={`https://app.rdyinventory.com/box-code/${box.code}`}
          />
        </div>
        <div className="grid gap-4 grid-cols-2 mt-6">
          <Button variant={"outline"} onClick={() => setIsOpen(false)}>
            Close
          </Button>
        </div>
      </PopoverContent>
    </Popover>
  );
};
