import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import logger from "redux-logger";
import rootSaga from "./rootSaga.ts";
import picturesSlice from "./pictures/picturesSlice.ts";
import itemsSlice from "./items/itemsSlice.ts";
import sessionSlice from "./session/sessionSlice.ts";
import boxesSlice from "./boxes/boxesSlice.ts";
import tagsSlice from "@/tags/tagsSlice.ts";

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: {
    boxes: boxesSlice,
    pictures: picturesSlice,
    items: itemsSlice,
    session: sessionSlice,
    tags: tagsSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(sagaMiddleware, logger),
});
export default store;

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
