import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
} from "@/components/ui/alert-dialog.tsx";
import { Button } from "@/components/ui/button.tsx";

interface PropTypes {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  itemName: string;
}

const DeleteConfirmationDialog = ({
  isOpen,
  onClose,
  onConfirm,
  itemName,
}: PropTypes) => {
  return (
    <AlertDialog open={isOpen}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader>Delete {itemName}?</AlertDialogHeader>
          <AlertDialogDescription>
            Are you sure you want to delete {itemName}? This action cannot be
            undone.
          </AlertDialogDescription>
          <AlertDialogFooter>
            <Button onClick={onClose}>Cancel</Button>
            <Button
              variant={"destructive"}
              onClick={onConfirm}
              className={"ml-3"}
            >
              Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default DeleteConfirmationDialog;
