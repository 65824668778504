import { Item, ItemCreate } from "./types";
import { map } from "lodash";
import { ApiPicture, mapPicture } from "@/pictures/api.ts";
import { api } from "@/api.ts";

export interface ApiItem {
  id: string;
  name: string;
  description: string;
  box_id?: string;
  created_at: string;
  updated_at: string;
  pictures: ApiPicture[];
}

export interface ApiItemUpdate {
  name?: string;
  description?: string;
  box_id?: string;
  picture_id?: string;
}

export interface PaginatedItems {
  items: Item[];
  total: number;
  skip: number;
  limit: number;
}
export interface PaginatedItemsResponse {
  items: ApiItem[];
  total: number;
  skip: number;
  limit: number;
}

function mapItem(item: ApiItem): Item {
  return {
    id: item.id,
    name: item.name,
    description: item.description,
    boxId: item.box_id,
    createdAt: item.created_at,
    updatedAt: item.updated_at,
    pictures: map(item.pictures, mapPicture),
  };
}

export interface ItemUpdate {
  id: string;
  name?: string;
  description?: string;
  boxId?: string;
  pictureIds?: string[];
  pictureId?: string;
}

function mapItemUpdate(item: ItemUpdate): ApiItemUpdate {
  return {
    name: item.name,
    description: item.description,
    box_id: item.boxId,
    picture_id: item.pictureId,
  };
}

export const itemApi = {
  fetchAllItems: async (): Promise<Item[]> => {
    const response = await api.get<ApiItem[]>("/items/all");
    return map(response, mapItem);
  },
  fetchItems: async (
    term: string = "",
    skip: number = 0,
    limit: number = 10,
  ): Promise<PaginatedItems> => {
    const response = await api.get<PaginatedItemsResponse>("/items", {
      params: {
        search: term,
        skip: skip.toString(),
        limit: limit.toString(),
      },
    });
    return {
      items: map(response.items, mapItem),
      total: response.total,
      skip: response.skip,
      limit: response.limit,
    };
  },

  fetchItemById: async (id: string): Promise<Item> => {
    const data = await api.get<ApiItem>(`/items/${id}`);
    return mapItem(data);
  },

  createItem: async (item: ItemCreate): Promise<Item> => {
    const response = await api.post<ApiItem>("/items", {
      name: item.name,
      description: item.description,
      picture_id: item.pictureId,
    });
    return mapItem(response);
  },

  updateItem: async (item: ItemUpdate): Promise<Item> => {
    const response = await api.put<ApiItem>(
      `/items/${item.id}`,
      mapItemUpdate(item),
    );
    return mapItem(response);
  },

  deleteItem: (id: string): Promise<string> =>
    api.delete<string>(`/items/${id}`).then(() => id),
};
