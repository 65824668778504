import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { API_BASE_URL } from "../config.ts";
import ErrorAlert from "../components/ErrorAlert.tsx";
import { Item } from "./types.ts";
import { getToken } from "../supabase.ts";
import { Button } from "@/components/ui/button.tsx";
import { Card } from "@/components/ui/card.tsx";
import ConfirmDelete from "@/components/ConfirmDelete.tsx";
import { itemApi } from "@/items/api.ts";
import { pictureUrl } from "@/pictures/utils.ts";
import * as Sentry from "@sentry/browser";

const ItemDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [item, setItem] = useState<Item | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  useEffect(() => {
    const fetchItem = async () => {
      try {
        if (!id) {
          return;
        }
        const i = await itemApi.fetchItemById(id);
        setItem(i);
        setLoading(false);
      } catch (err) {
        Sentry.captureException(err);
        setError("Failed to fetch item details. Please try again later.");
        setLoading(false);
      }
    };

    fetchItem();
  }, [id]);
  const handleEdit = () => {
    navigate(`/items/${id}/edit`);
  };

  const handleDelete = async () => {
    try {
      const response = await fetch(API_BASE_URL + `/items/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      navigate("/items");
    } catch (err) {
      Sentry.captureException(err);
      setError("Failed to delete item. Please try again later.");
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  if (error) {
    return <ErrorAlert>{error}</ErrorAlert>;
  }

  if (!item) {
    return <ErrorAlert>Item not found.</ErrorAlert>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <Card>
        <h1 className="text-2xl font-bold">{item.name}</h1>
        <p className="text-gray-700">{item.description}</p>
        <h2 className="text-xl font-semibold mb-2">Pictures</h2>
        <div className="grid grid-cols-3 gap-4">
          {item.pictures.map((picture) => (
            <div key={picture.id} className="border rounded p-2">
              <img
                src={pictureUrl(picture)}
                alt={picture.name || "Item picture"}
                className="w-full h-auto max-h-96 object-contain mb-4 rounded-t-lg"
              />
              <p className="mt-2 text-sm">{picture.name}</p>
            </div>
          ))}
        </div>
        {/* Add more item details here as needed */}
        <div className="flex justify-end space-x-2">
          <Button onClick={handleEdit}>Edit</Button>
          <Button onClick={() => setIsDeleteDialogOpen(true)}>Delete</Button>
        </div>
      </Card>
      <ConfirmDelete
        isOpen={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        onConfirm={handleDelete}
        itemName={"item"}
      />
    </div>
  );
};

export default ItemDetail;
