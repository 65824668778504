import { ChevronLeftIcon, ChevronRightIcon } from "lucide-react";
import React from "react";

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  handlePrevPage: () => void;
  handleNextPage: () => void;
}

const Pagination: React.FC<PaginationProps> = (props) => {
  return (
    <div className={"flex justify-center"}>
      <button
        onClick={props.handlePrevPage}
        disabled={props.currentPage === 1}
        className="flex items-center px-4 py-2 border rounded-md text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
      >
        <ChevronLeftIcon className="h-5 w-5 mr-2" />
        Previous
      </button>
      <span className="text-sm text-gray-700">
        Page {props.currentPage} of {props.totalPages}
      </span>
      <button
        onClick={props.handleNextPage}
        disabled={props.currentPage === props.totalPages}
        className="flex items-center px-4 py-2 border rounded-md text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
      >
        Next
        <ChevronRightIcon className="h-5 w-5 ml-2" />
      </button>
    </div>
  );
};

export default Pagination;
