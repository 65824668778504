import { map } from "lodash";
import { Link } from "react-router-dom";
import { selectPicturesPage } from "./picturesSlice.ts";
import { useSelector } from "react-redux";
import Pagination from "@/pictures/components/Pagination.tsx";
import { pictureUrl } from "@/pictures/utils.ts";

const ListPictures = () => {
  const pictures = useSelector(selectPicturesPage);

  return (
    <div className="container mx-auto px-4 py-8">
      <h2 className="text-2xl font-bold mb-4">Picture Inbox</h2>
      <div className="my-8">
        <Link to="/pictures/new" className="text-blue-600 hover:underline">
          Upload a new picture
        </Link>
      </div>
      <div className="my-8 flex justify-center items-center space-x-4">
        <Pagination />
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {map(pictures, (picture) => (
          <div
            key={picture.id}
            className="bg-white rounded-lg shadow-md overflow-hidden"
          >
            <img
              src={pictureUrl(picture)}
              alt={picture.name}
              className="w-full h-auto max-h-96 object-contain mb-4 rounded-t-lg"
            />
            <div className="p-4">
              <p className="text-sm font-semibold truncate">{picture.name}</p>
              <p className="text-sm font-semibold truncate">
                {picture.description}
              </p>
              <p className="text-xs text-gray-500">
                {new Date(picture.uploadDate).toLocaleDateString()}
              </p>
              <p>
                <Link
                  to={`/pictures/${picture.id}`}
                  className="text-blue-600 hover:underline"
                >
                  View
                </Link>
              </p>
            </div>
          </div>
        ))}
      </div>
      <div className="mt-8 flex justify-center items-center space-x-4">
        <Pagination />
      </div>
      <div className="mt-8">
        <Link to="/pictures/new" className="text-blue-600 hover:underline">
          Upload a new picture
        </Link>
      </div>
    </div>
  );
};

export default ListPictures;
