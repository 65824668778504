import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@/components/ui/button";
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog.tsx";
import { selectLoadingError, updateBoxRequest } from "@/boxes/boxesSlice.ts";
import { Box } from "@/boxes/types.ts";
import BoxSearch from "@/boxes/components/BoxSearch.tsx";

interface AttachBoxButtonPropTypes {
  pictureId: string;
}

const AttachBoxButton = ({ pictureId }: AttachBoxButtonPropTypes) => {
  const dispatch = useDispatch();
  const { loading, error } = useSelector(selectLoadingError);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedBox, setSelectedBox] = useState<Box | null>(null);

  const handleUpdateBox = () => {
    if (!selectedBox) {
      return;
    }
    dispatch(updateBoxRequest({ pictureId, id: selectedBox.id }));
  };

  return (
    <>
      <AlertDialog open={isOpen}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>Add to Existing Box</AlertDialogTitle>
            </AlertDialogHeader>
            <AlertDialogDescription className={"space-y-4"}>
              <BoxSearch
                onSelect={function (box: Box): void {
                  setSelectedBox(box);
                }}
              />

              {error && <div className="text-red-500">{error}</div>}
            </AlertDialogDescription>
            <AlertDialogFooter>
              <Button onClick={() => setIsOpen(false)}>Cancel</Button>
              <Button
                onClick={handleUpdateBox}
                disabled={!selectedBox || loading}
              >
                {loading
                  ? "Creating..."
                  : `Attach Picture to ${selectedBox?.name || "Box"}`}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <Button onClick={() => setIsOpen(true)}>Add to Existing Box</Button>
    </>
  );
};

export default AttachBoxButton;
