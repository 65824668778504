import React from "react";
import { Input } from "@/components/ui/input.tsx";
import { Loader2 } from "lucide-react";
import { Button } from "@/components/ui/button.tsx";
import { Box } from "@/boxes/types.ts";
import { useDispatch, useSelector } from "react-redux";
import {
  selectLoadingError,
  selectMatchingBoxes,
  selectSearchTerm,
  setSearchTerm,
} from "@/boxes/boxesSlice.ts";

interface BoxSearchProps {
  onSelect: (box: Box) => void;
}

const BoxSearch: React.FC<BoxSearchProps> = ({ onSelect }) => {
  const dispatch = useDispatch();
  const results = useSelector(selectMatchingBoxes);
  const searchTerm = useSelector(selectSearchTerm);
  const { loading, error } = useSelector(selectLoadingError);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const term = e.target.value;
    dispatch(setSearchTerm(term));
  };

  return (
    <div className="w-full max-w-sm space-y-2">
      <Input
        type="text"
        placeholder="Search boxes..."
        value={searchTerm}
        onChange={handleInputChange}
      />
      {loading && (
        <div className="flex items-center justify-center">
          <Loader2 className="h-6 w-6 animate-spin" />
        </div>
      )}
      {error && <p className="text-red-500">Error: {error}</p>}
      {!loading && results.length === 0 && searchTerm.length > 2 && (
        <p>No results found</p>
      )}
      {results.length > 0 && (
        <>
          <ul className="space-y-1">
            {results.map((item) => (
              <li key={item.id}>
                <Button
                  variant="ghost"
                  className="w-full justify-start"
                  onClick={() => onSelect(item)}
                >
                  {item.name}
                </Button>
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
};

export default BoxSearch;
