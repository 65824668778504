import { Check, ChevronsUpDown } from "lucide-react";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { useEffect, useState } from "react";
import { selectAllItems } from "@/items/itemsSlice.ts";
import { useSelector } from "react-redux";
import { find, map } from "lodash";
import { Item } from "@/items/types.ts";

interface SelectItemPropTypes {
  onSelect: (item: Item) => void;
  selectedId?: string | null;
}

export function SelectItem(props: SelectItemPropTypes) {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("");
  const items = useSelector(selectAllItems);

  useEffect(() => {
    if (props.selectedId) {
      const name = find(items, (item) => item.id === props.selectedId)?.name;
      setValue(name ? name : "");
    } else {
      setValue("");
    }
  }, [items, props.selectedId]);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-[300px] justify-between"
        >
          {value
            ? find(items, (item) => item.name === value)?.name
            : "Select item..."}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[400px] p-0">
        <Command>
          <CommandInput placeholder="Search item..." />
          <CommandList>
            <CommandEmpty>No item found.</CommandEmpty>
            <CommandGroup>
              {map(items, (item) => (
                <CommandItem
                  key={item.id}
                  value={item.name}
                  onSelect={(currentValue) => {
                    props.onSelect(item);
                    setValue(currentValue === value ? "" : currentValue);
                    setOpen(false);
                  }}
                >
                  <Check
                    className={cn(
                      "mr-2 h-4 w-4",
                      value === item.name ? "opacity-100" : "opacity-0",
                    )}
                  />
                  {item.name}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
