import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@/components/ui/button";
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog.tsx";
import { selectLoadingError, updateItemRequest } from "@/items/itemsSlice.ts";
import ItemSearch from "@/items/components/ItemSearch.tsx";
import { Item } from "@/items/types.ts";

interface CreateBoxButtonPropTypes {
  pictureId: string;
}

const AttachItemButton = ({ pictureId }: CreateBoxButtonPropTypes) => {
  const dispatch = useDispatch();
  const { loading, error } = useSelector(selectLoadingError);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<Item | null>(null);

  const handleUpdateItem = () => {
    if (!selectedItem) {
      return;
    }
    dispatch(updateItemRequest({ pictureId, id: selectedItem.id }));
  };

  return (
    <>
      <AlertDialog open={isOpen}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>Add to Existing Item</AlertDialogTitle>
            </AlertDialogHeader>
            <AlertDialogDescription className={"space-y-4"}>
              <ItemSearch
                onSelect={function (item: Item): void {
                  setSelectedItem(item);
                }}
              />

              {error && <div className="text-red-500">{error}</div>}
            </AlertDialogDescription>
            <AlertDialogFooter>
              <Button onClick={() => setIsOpen(false)}>Cancel</Button>
              <Button
                onClick={handleUpdateItem}
                disabled={!selectedItem || loading}
              >
                {loading
                  ? "Creating..."
                  : `Attach Picture to ${selectedItem?.name || "Item"}`}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <Button onClick={() => setIsOpen(true)}>Add to Existing Item</Button>
    </>
  );
};

export default AttachItemButton;
