import { X } from "lucide-react";
import { Tag } from "@/tags/types.ts";

interface TagDisplayPropTypes {
  tags: Tag[];
  onRemove?: (tag: string) => void;
  editable?: boolean;
}

const TagDisplay = ({
  tags,
  onRemove,
  editable = false,
}: TagDisplayPropTypes) => {
  return (
    <div className="flex flex-wrap gap-2">
      {tags.map((tag, index) => (
        <span
          key={index}
          className="inline-flex items-center px-2 py-1 rounded-full text-sm font-medium bg-blue-100 text-blue-800"
        >
          {tag.name}
          {editable && onRemove && (
            <button
              onClick={() => onRemove(tag.id)}
              className="ml-1 text-blue-600 hover:text-blue-800 focus:outline-none"
            >
              <X size={14} />
            </button>
          )}
        </span>
      ))}
      {tags.length === 0 && (
        <span className="text-gray-500 italic">No tags</span>
      )}
    </div>
  );
};

export default TagDisplay;
