import { useState } from "react";
import { Control, useController } from "react-hook-form";
import { XCircle, Plus } from "lucide-react";
import { PictureEditFormData } from "./PictureEditForm.tsx";
import { Tag } from "@/tags/types.ts";
import { map } from "lodash";

interface TagManagementPropTypes {
  control: Control<PictureEditFormData, "tags">;
  name: "tags";
}

const TagManagement = ({ control, name }: TagManagementPropTypes) => {
  const [newTag, setNewTag] = useState("");
  const {
    field: { value: tags, onChange },
  } = useController<PictureEditFormData, "tags">({
    name,
    control,
    defaultValue: [],
  });

  const handleAddTag = () => {
    if (newTag.trim() && !map(tags, "name").includes(newTag.trim())) {
      onChange([...tags, newTag.trim()]);
      setNewTag("");
    }
  };

  const handleRemoveTag = (tagToRemove: string) => {
    onChange(tags.filter((tag: Tag) => tag.id !== tagToRemove));
  };

  return (
    <div className="space-y-2">
      <div className="flex space-x-2">
        <input
          type="text"
          value={newTag}
          onChange={(e) => setNewTag(e.target.value)}
          placeholder="Add a tag"
          className="flex-grow px-2 py-1 border rounded"
        />
        <button
          type="button"
          onClick={handleAddTag}
          className="px-2 py-1 text-white bg-blue-500 rounded hover:bg-blue-600"
        >
          <Plus size={20} />
        </button>
      </div>
      <div className="flex flex-wrap gap-2">
        {tags.map((tag: Tag) => (
          <span
            key={tag.id}
            className="flex items-center px-2 py-1 text-sm bg-gray-200 rounded"
          >
            {tag.name}
            <button
              type="button"
              onClick={() => handleRemoveTag(tag.id)}
              className="ml-1 text-red-500 hover:text-red-700"
            >
              <XCircle size={16} />
            </button>
          </span>
        ))}
      </div>
    </div>
  );
};

export default TagManagement;
