import {
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
  takeLeading,
} from "redux-saga/effects";
import {
  addPicture,
  deletePictureRequest,
  describePictureRequest,
  fetchPictureRequest,
  fetchPicturesRequest,
  removePicture,
  setPicture,
  setPictures,
  updatePicture,
  updatePictureRequest,
} from "./picturesSlice.ts";
import { PayloadAction } from "@reduxjs/toolkit";
import { FetchPicturesResponse, pictureApi } from "@/pictures/api.ts";
import { Picture } from "@/pictures/types.ts";
import localforage from "localforage";
import * as Sentry from "@sentry/browser";

function* handleFetchPictures({ payload: page }: PayloadAction<number>) {
  try {
    const response: FetchPicturesResponse = yield call(
      pictureApi.fetchPictures,
      page,
    );
    yield put(setPictures(response.pictures));
  } catch (error) {
    Sentry.captureException(error);
    console.error("Failed to fetch pictures:", error);
    // You might want to dispatch an error action here
  }
}

export function* handleFetchPictureById({
  payload: id,
}: PayloadAction<string>) {
  try {
    const picture: Picture = yield call(pictureApi.fetchPictureById, id);
    yield put(addPicture(picture));
  } catch (error) {
    Sentry.captureException(error);
    console.error("Failed to fetch picture:", error);
    // You might want to dispatch an error action here
  }
}

function* handleUpdatePicture({
  payload,
}: PayloadAction<Partial<Picture> & { id: string }>) {
  try {
    const updatedPicture: Picture = yield call(
      pictureApi.updatePicture,
      payload,
    );
    yield put(updatePicture(updatedPicture));
  } catch (error) {
    Sentry.captureException(error);
    console.error("Failed to update picture:", error);
    // You might want to dispatch an error action here
  }
}

function* handleDeletePicture({ payload: id }: PayloadAction<string>) {
  try {
    yield call(pictureApi.deletePicture, id);
    yield put(removePicture(id));
  } catch (error) {
    Sentry.captureException(error);
    console.error("Failed to delete picture:", error);
    // You might want to dispatch an error action here
  }
}

function* handleDescribePicture({ payload: id }: PayloadAction<string>) {
  try {
    const picture: Picture = yield call(pictureApi.describePicture, id);
    yield put(setPicture(picture));
  } catch (error) {
    Sentry.captureException(error);
    console.error("Failed to describe picture:", error);
    // You might want to dispatch an error action here
  }
}

function* handleFetchAllPictures() {
  try {
    const pictures: Picture[] = yield call(pictureApi.fetchAllPictures);
    yield put(setPictures(pictures));
    yield call([localforage, localforage.setItem], "pictures", pictures);
  } catch (error) {
    Sentry.captureException(error);
    console.error("Failed to fetch all pictures:", error);
    // You might want to dispatch an error action here
  }
}

function* loadFromLocalForage() {
  const pictures: Picture[] = yield call(
    [localforage, localforage.getItem],
    "pictures",
  );
  if (pictures) {
    yield put(setPictures(pictures));
  }
}

export function* picturesSaga() {
  yield fork(loadFromLocalForage);
  yield fork(handleFetchAllPictures);
  yield takeLeading(fetchPicturesRequest.type, handleFetchPictures);
  yield takeLeading(fetchPictureRequest.type, handleFetchPictureById);
  yield takeLatest(updatePictureRequest.type, handleUpdatePicture);
  yield takeLatest(deletePictureRequest.type, handleDeletePicture);
  yield takeEvery(describePictureRequest.type, handleDescribePicture);
}
