import { useForm } from "react-hook-form";
import TagManagement from "./TagManagement.tsx";
import { Label } from "@/components/ui/label.tsx";
import { Button } from "@/components/ui/button.tsx";
import { Textarea } from "@/components/ui/textarea.tsx";
import { Input } from "@/components/ui/input.tsx";
import { Tag } from "@/tags/types.ts";
import { Picture } from "@/pictures/types.ts";

interface PictureEditFormPropTypes {
  picture: Picture;
  onSubmit: (data: PictureEditFormData) => void;
}

export interface PictureEditFormData {
  name: string;
  description: string;
  tags: Tag[];
}

const PictureEditForm = (props: PictureEditFormPropTypes) => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<PictureEditFormData>({
    defaultValues: {
      name: props.picture.name || "",
      description: props.picture.description || "",
      tags: props.picture.tags || [],
    },
  });

  const onSubmitForm = (data: PictureEditFormData) => {
    props.onSubmit(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmitForm)} className="space-y-4">
      <div>
        <Label htmlFor="name">Name</Label>
        <Input
          id="name"
          {...register("name", { required: "Name is required" })}
          className={errors.name ? "border-red-500" : ""}
        />
        {errors.name && (
          <p className="text-red-500 text-sm mt-1">{errors.name.message}</p>
        )}
      </div>

      <div>
        <Label htmlFor="description">Description</Label>
        <Textarea
          id="description"
          {...register("description")}
          className="h-32"
        />
      </div>

      <div>
        <label className="block mb-1">Tags</label>
        <TagManagement control={control} name="tags" />
      </div>

      <Button type="submit">Save Changes</Button>
    </form>
  );
};

export default PictureEditForm;
