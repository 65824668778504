import { useRef } from "react";
import { Control, Controller, useFieldArray } from "react-hook-form";
import { NewPictureFormData } from "./types.ts";
import { Button } from "@/components/ui/button.tsx";
import { Label } from "@/components/ui/label.tsx";

interface UploadPicturesProps {
  control: Control<NewPictureFormData>;
}

const UploadPictures = ({ control }: UploadPicturesProps) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "pictures",
    keyName: "pictureId",
  });

  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const onAddPictures = () => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  const handleAddPictures = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (!event.target.files) {
      console.log("no files");
      return;
    }
    const uploadedFiles = Array.from(event.target.files);

    const files = uploadedFiles.map((file) => ({
      file,
    }));

    append(files);

    if (hiddenFileInput.current) {
      hiddenFileInput.current.value = "";
    }
  };

  return (
    <>
      <input
        className={"hidden"}
        ref={hiddenFileInput}
        type="file"
        multiple
        capture={"environment"}
        accept={"image/*"}
        onChange={handleAddPictures}
      />

      <div>
        <Label>Pictures</Label>

        {fields.map(({ pictureId, file }, index) => (
          <div key={pictureId}>
            <Controller
              control={control}
              name={`pictures.${index}`}
              render={() => (
                <div className={"container grid items-center"}>
                  <div>
                    <img
                      className={"w-20 h-20"}
                      src={URL.createObjectURL(file)}
                      alt={file.name}
                    />
                  </div>

                  <div>{file.name}</div>

                  <div>
                    <Button aria-label="Remove" onClick={() => remove(index)}>
                      delete
                    </Button>
                  </div>
                </div>
              )}
            />
          </div>
        ))}

        <Button onClick={onAddPictures}>Add pictures</Button>
      </div>
    </>
  );
};

export default UploadPictures;
