import { useState } from "react";
import { useForm } from "react-hook-form";
import { API_BASE_URL } from "../config.ts";
import { getToken } from "../supabase.ts";
import ErrorAlert from "../components/ErrorAlert.tsx";
import { Link } from "react-router-dom";
import { Label } from "@/components/ui/label.tsx";
import { Input } from "@/components/ui/input.tsx";
import { Textarea } from "@/components/ui/textarea.tsx";
import { Button } from "@/components/ui/button.tsx";
import { Alert } from "@/components/ui/alert.tsx";
import * as Sentry from "@sentry/browser";

interface CreateItemFormData {
  name: string;
  description: string;
}

const CreateItem = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<CreateItemFormData>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState("");
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const onSubmit = async (data: CreateItemFormData) => {
    setIsSubmitting(true);
    setSubmitError("");
    setSubmitSuccess(false);

    try {
      console.log("creating item", data);
      const response = await fetch(API_BASE_URL + "/items", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      setSubmitSuccess(true);
      reset();
    } catch (error) {
      Sentry.captureException(error);
      setSubmitError("Failed to create item. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-2xl font-bold mb-4">Create New Item</h1>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
        <div>
          <Label htmlFor="name">Name</Label>
          <Input
            id="name"
            {...register("name", { required: "Name is required" })}
            className={errors.name ? "border-red-500" : ""}
          />
          {errors.name && (
            <p className="text-red-500 text-sm mt-1">{errors.name.message}</p>
          )}
        </div>
        <div>
          <Label htmlFor="description">Description</Label>
          <Textarea
            id="description"
            {...register("description")}
            className={errors.description ? "border-red-500" : ""}
          />
          {errors.description && (
            <p className="text-red-500 text-sm mt-1">
              {errors.description.message}
            </p>
          )}
        </div>
        <Button type="submit" disabled={isSubmitting}>
          {isSubmitting ? "Creating..." : "Create Item"}
        </Button>
      </form>
      {submitError && <ErrorAlert>{submitError}</ErrorAlert>}
      {submitSuccess && (
        <Alert className="mt-4">Item created successfully!</Alert>
      )}
      <Link to={"/items"} className="text-blue-500 hover:text-blue-700">
        Back to items
      </Link>
    </div>
  );
};

export default CreateItem;
