import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectBoxByCode } from "@/boxes/boxesSlice.ts";
import { RootState } from "@/store.ts";
import { Box } from "@/boxes/types.ts";

export const CodeRedirect: React.FC = () => {
  const { code } = useParams<{ code: string }>();
  const navigate = useNavigate();
  const box = useSelector<RootState, Box | null>((state) =>
    selectBoxByCode(state, code),
  );

  useEffect(() => {
    if (box) {
      navigate(`/boxes/${box.id}`);
    }
  }, [box, code, navigate]);

  return null;
};
