import { createClient } from "@supabase/supabase-js";

export const supabaseUrl = "https://fnhwgfuuvfrcjdssxeml.supabase.co";
export const supabaseAnonKey =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImZuaHdnZnV1dmZyY2pkc3N4ZW1sIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjE0NDYyMzgsImV4cCI6MjAzNzAyMjIzOH0.i9cS11hv7CzyJXZp0qYUbtn24hXt5krOF2RpnPtb3CA";
export const supabase = createClient(supabaseUrl, supabaseAnonKey);

export const getToken = () => {
  const supabaseProjectId = "fnhwgfuuvfrcjdssxeml";
  const storageKey = `sb-${supabaseProjectId}-auth-token`;
  const sessionDataString = localStorage.getItem(storageKey);
  const sessionData = JSON.parse(sessionDataString || "null");
  const accessToken = sessionData?.access_token;
  return accessToken;
};
