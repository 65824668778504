import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover.tsx";
import { SelectItem } from "@/items/components/SelectItem.tsx";
import { Controller, useForm } from "react-hook-form";
import React, { useState } from "react";
import { Item } from "@/items/types.ts";
import { Button, buttonVariants } from "@/components/ui/button.tsx";

interface AddItemForm {
  itemId: string;
}

interface AddItemPopoverProps {
  onSubmit: (values: AddItemForm) => void;
}

const AddItemPopover: React.FC<AddItemPopoverProps> = (props) => {
  const { control, handleSubmit } = useForm<AddItemForm>();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Popover open={isOpen}>
      <PopoverTrigger
        className={buttonVariants()}
        onClick={() => setIsOpen(true)}
      >
        Add Item to Box
      </PopoverTrigger>
      <PopoverContent className={"w-96"}>
        <p className={"my-2"}>Select item to add to box</p>
        <form onSubmit={handleSubmit(props.onSubmit)}>
          <Controller
            name="itemId"
            control={control}
            render={({ field }) => (
              <SelectItem
                selectedId={field.value}
                onSelect={(item: Item) => {
                  field.onChange(item.id);
                }}
              />
            )}
          />
          <div className="grid gap-4 grid-cols-2 mt-6">
            <Button variant={"outline"} onClick={() => setIsOpen(false)}>
              Cancel
            </Button>
            <Button
              variant={"default"}
              onClick={() => {
                handleSubmit(props.onSubmit);
                setIsOpen(false);
              }}
            >
              Add
            </Button>
          </div>
        </form>
      </PopoverContent>
    </Popover>
  );
};

export default AddItemPopover;
