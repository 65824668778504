import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentPage,
  selectTotalPages,
  setCurrentPage,
} from "@/pictures/picturesSlice.ts";
import React from "react";
import Paginator from "@/components/Paginator.tsx";

interface PaginationProps {}

const Pagination: React.FC<PaginationProps> = () => {
  const currentPage = useSelector(selectCurrentPage);
  const totalPages = useSelector(selectTotalPages);

  const dispatch = useDispatch();

  const handlePrevPage = () => {
    if (currentPage > 1) {
      dispatch(setCurrentPage(currentPage - 1));
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      dispatch(setCurrentPage(currentPage + 1));
    }
  };
  return (
    <Paginator
      currentPage={currentPage}
      totalPages={totalPages}
      handleNextPage={handleNextPage}
      handlePrevPage={handlePrevPage}
    />
  );
};

export default Pagination;
