import { AlertCircle } from "lucide-react";

import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
interface ErrorAlertPropTypes {
  children: React.ReactNode;
  className?: string;
}

function ErrorAlert(props: ErrorAlertPropTypes) {
  return (
    <Alert variant="destructive">
      <AlertCircle className="h-4 w-4" />
      <AlertTitle>Error</AlertTitle>
      <AlertDescription>{props.children}</AlertDescription>
    </Alert>
  );
}

export default ErrorAlert;
