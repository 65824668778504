import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { createBoxStart, selectLoadingError } from "@/boxes/boxesSlice.ts";
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog.tsx";

interface CreateBoxButtonPropTypes {
  pictureId: string;
}

const CreateBoxButton = ({ pictureId }: CreateBoxButtonPropTypes) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const dispatch = useDispatch();
  const { loading, error } = useSelector(selectLoadingError);
  const [isOpen, setIsOpen] = useState(false);

  const handleCreateBox = () => {
    const payload = { name, description };
    Object.assign(payload, { pictureId });
    dispatch(createBoxStart(payload));
  };

  return (
    <>
      <AlertDialog open={isOpen}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>Create New Box</AlertDialogTitle>
            </AlertDialogHeader>
            <AlertDialogDescription className={"space-y-4"}>
              <Input
                placeholder="Box Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <Textarea
                placeholder="Box Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />

              {error && <div className="text-red-500">{error}</div>}
            </AlertDialogDescription>
            <AlertDialogFooter>
              <Button onClick={() => setIsOpen(false)}>Cancel</Button>
              <Button onClick={handleCreateBox} disabled={loading}>
                {loading ? "Creating..." : "Create Box"}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <Button onClick={() => setIsOpen(true)}>Create New Box</Button>
    </>
  );
};

export default CreateBoxButton;
