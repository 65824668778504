import "./App.css";
import LandingPage from "./pages/LandingPage.tsx";
import { useDispatch, useSelector } from "react-redux";
import { isLoading, isLoggedIn, setSession } from "./session/sessionSlice.ts";
import { useEffect } from "react";
import { supabase } from "./supabase.ts";
import router from "./routes.tsx";
import { RouterProvider } from "react-router-dom";
import LoadingSpinner from "@/components/LoadingSpinner.tsx";

function App() {
  const loggedIn = useSelector(isLoggedIn);
  const loading = useSelector(isLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      dispatch(setSession(session));
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      dispatch(setSession(session));
    });

    return () => subscription.unsubscribe();
  }, [dispatch]);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (!loggedIn) {
    return <LandingPage />;
  }

  return (
    <>
      <h1 className={"text-3xl"}>RDY Inventory</h1>

      <RouterProvider router={router} />
    </>
  );
}

export default App;
