import { FC } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@/store.ts";
import { Item } from "@/items/types.ts";
import { selectItemById } from "@/items/itemsSlice.ts";
import { selectBoxById } from "@/boxes/boxesSlice.ts";
import { Box } from "@/boxes/types.ts";
import { Image, Images } from "lucide-react";
import { TableCell, TableRow } from "@/components/ui/table.tsx";
import { Link } from "react-router-dom";

interface ItemsListRowProps {
  id: string;
}

const ItemsListRow: FC<ItemsListRowProps> = (props) => {
  const item = useSelector<RootState, Item | null>((state) =>
    selectItemById(state, props.id),
  );
  const box = useSelector<RootState, Box | null>((state) =>
    item?.boxId ? selectBoxById(state, item?.boxId) : null,
  );

  if (!item) {
    return null;
  }

  return (
    <TableRow>
      <TableCell className="font-medium">
        <Link
          to={`/items/${item.id}`}
          className="text-blue-500 hover:text-blue-700"
        >
          {item.name}
        </Link>
      </TableCell>
      <TableCell>{item.description}</TableCell>
      <TableCell>{box?.name}</TableCell>
      <TableCell>
        {" "}
        {item.pictures.length === 1 ? (
          <Image />
        ) : item.pictures.length > 1 ? (
          <Images />
        ) : null}
      </TableCell>
    </TableRow>
  );
};

export default ItemsListRow;
