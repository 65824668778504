import { call, fork, put, select, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { Box, FetchBoxesResponse } from "./types.ts";
import {
  createBoxFailure,
  createBoxStart,
  createBoxSuccess,
  deleteBoxFailure,
  deleteBoxStart,
  deleteBoxSuccess,
  fetchBoxesFailure,
  fetchBoxesStart,
  fetchBoxesSuccess,
  fetchBoxFailure,
  fetchBoxStart,
  fetchBoxSuccess,
  generateBoxCodeRequest,
  searchBoxesFailure,
  searchBoxesStart,
  searchBoxesSuccess,
  selectAllBoxes,
  setBoxes,
  updateBoxFailure,
  updateBoxRequest,
  updateBoxStart,
  updateBoxSuccess,
} from "./boxesSlice.ts";
import router from "../routes.tsx";
import { boxApi, BoxCreate, BoxUpdate } from "@/boxes/api.ts";
import localforage from "localforage";
import * as Sentry from "@sentry/browser";
import { handleFetchPictureById } from "@/pictures/picturesSaga.ts";
import { fetchPictureRequest } from "@/pictures/picturesSlice.ts";

function* cacheBoxes() {
  const boxes: Box[] = yield select(selectAllBoxes);
  yield call([localforage, localforage.setItem], "boxes", boxes);
}

// Sagas
function* fetchBoxesSaga() {
  try {
    const response: FetchBoxesResponse = yield call(boxApi.fetchBoxes);
    yield put(fetchBoxesSuccess(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(fetchBoxesFailure((error as Error).message));
  }
}

function* fetchBoxSaga(action: PayloadAction<string>) {
  try {
    const box: Box = yield call(boxApi.fetchBox, action.payload);
    yield put(fetchBoxSuccess(box));
  } catch (error) {
    Sentry.captureException(error);
    yield put(fetchBoxFailure((error as Error).message));
  }
}

function* createBoxSaga(action: PayloadAction<BoxCreate>) {
  try {
    const newBox: Box = yield call(boxApi.createBox, action.payload);
    yield put(createBoxSuccess(newBox));
    if (action.payload.pictureId) {
      yield call(
        handleFetchPictureById,
        fetchPictureRequest(action.payload.pictureId),
      );
    }
    yield call(router.navigate, "/boxes");
  } catch (error) {
    Sentry.captureException(error);
    yield put(createBoxFailure((error as Error).message));
  }
}

function* updateBoxSaga(
  action: PayloadAction<{ id: string; update: BoxUpdate }>,
) {
  try {
    const updatedBox: Box = yield call(
      boxApi.updateBox,
      action.payload.id,
      action.payload.update,
    );
    yield put(updateBoxSuccess(updatedBox));
    yield call(router.navigate, "/boxes");
  } catch (error) {
    Sentry.captureException(error);
    yield put(updateBoxFailure((error as Error).message));
  }
}

function* handleUpdateBoxRequest(action: PayloadAction<BoxUpdate>) {
  try {
    const updatedBox: Box = yield call(
      boxApi.updateBox,
      action.payload.id,
      action.payload,
    );
    if (action.payload.pictureId) {
      yield call(
        handleFetchPictureById,
        fetchPictureRequest(action.payload.pictureId),
      );
    }
    yield put(updateBoxSuccess(updatedBox));
    yield call(router.navigate, `/boxes/${action.payload.id}`);
  } catch (error) {
    Sentry.captureException(error);
    console.error(error);
  }
}

function* deleteBoxSaga(action: PayloadAction<string>) {
  try {
    yield call(boxApi.deleteBox, action.payload);
    yield put(deleteBoxSuccess(action.payload));
    yield call(router.navigate, "/boxes");
  } catch (error) {
    Sentry.captureException(error);
    yield put(deleteBoxFailure((error as Error).message));
  }
}

function* searchBoxesSaga(
  action: PayloadAction<{ searchTerm: string; page: number }>,
) {
  try {
    const { searchTerm } = action.payload;
    if (!searchTerm) {
      yield call(fetchBoxesSaga);
      return;
    }
    // const limit = 10; // You can adjust this or make it configurable
    // const skip = (page - 1) * limit;
    const boxes: Box[] = yield call(boxApi.searchBoxes, searchTerm);
    // const totalCount: number = yield call(boxApi.getTotalBoxCount, searchTerm); // todo
    yield put(searchBoxesSuccess({ boxes, totalCount: 0 }));
  } catch (error) {
    Sentry.captureException(error);
    yield put(searchBoxesFailure((error as Error).message));
  }
}

function* handleFetchAllBoxes() {
  try {
    const boxes: Box[] = yield call(boxApi.fetchAllBoxes);
    yield put(setBoxes(boxes));
    yield call(cacheBoxes);
  } catch (error) {
    Sentry.captureException(error);
    yield put(fetchBoxesFailure((error as Error).message));
  }
}

function* handleGenerateBoxCode(action: PayloadAction<string>) {
  try {
    const box: Box = yield call(boxApi.generateBoxCode, action.payload);
    yield put(updateBoxSuccess(box));
    yield call(cacheBoxes);
  } catch (error) {
    Sentry.captureException(error);
    console.error(error);
  }
}

function* loadFromLocalForage() {
  const boxes: Box[] = yield call([localforage, localforage.getItem], "boxes");
  if (boxes) {
    yield put(setBoxes(boxes));
  }
}

export function* boxSaga() {
  yield fork(loadFromLocalForage);
  yield fork(handleFetchAllBoxes);
  yield takeLatest(fetchBoxesStart.type, fetchBoxesSaga);
  yield takeLatest(fetchBoxStart.type, fetchBoxSaga);
  yield takeLatest(createBoxStart.type, createBoxSaga);
  yield takeLatest(updateBoxStart.type, updateBoxSaga);
  yield takeLatest(deleteBoxStart.type, deleteBoxSaga);
  yield takeLatest(searchBoxesStart.type, searchBoxesSaga);
  yield takeLatest(updateBoxRequest.type, handleUpdateBoxRequest);
  yield takeLatest(generateBoxCodeRequest.type, handleGenerateBoxCode);
}
