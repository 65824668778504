import { call, fork, put, select, take } from "redux-saga/effects";
import { picturesSaga } from "./pictures/picturesSaga.ts";
import { itemsSaga } from "./items/itemsSaga.ts";
import { API_BASE_URL } from "./config.ts";
import {
  isLoggedIn,
  setBackendAvailable,
  setSession,
} from "./session/sessionSlice.ts";
import { boxSaga } from "./boxes/boxesSaga.ts";
import tagsSaga from "@/tags/tagsSaga.ts";

function* waitForSession() {
  const loggedIn: boolean = yield select(isLoggedIn);
  if (loggedIn) {
    return;
  }
  while (true) {
    yield take(setSession.type);
    const loggedIn: boolean = yield select(isLoggedIn);
    if (loggedIn) {
      return;
    }
  }
}

function waitForBackend() {
  return fetch(API_BASE_URL + "/healthcheck");
}

export default function* rootSaga() {
  yield call(console.log, "rdy app loaded");
  yield call(waitForBackend);
  yield put(setBackendAvailable(true));
  yield call(waitForSession);
  yield fork(picturesSaga);
  yield fork(itemsSaga);
  yield fork(boxSaga);
  yield fork(tagsSaga);
}
