import React, { useCallback, useState } from "react";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Loader2 } from "lucide-react";
import debounce from "lodash/debounce";
import { Item } from "@/items/types.ts";
import { itemApi, PaginatedItems } from "@/items/api.ts";
import * as Sentry from "@sentry/browser";

const useSearchItems = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const searchItems = async (
    term: string,
    skip: number = 0,
    limit: number = 10,
  ): Promise<PaginatedItems> => {
    setLoading(true);
    setError(null);
    try {
      return await itemApi.fetchItems(term, skip, limit);
    } catch (err) {
      Sentry.captureException(err);
      console.error(err);
      if (err instanceof Error) {
        setError(err.message);
      } else {
        setError("An unknown error occurred");
      }
      return { items: [], total: 0, skip: 0, limit: 0 };
    } finally {
      setLoading(false);
    }
  };

  return { searchItems, loading, error };
};

interface ItemSearchProps {
  onSelect: (item: Item) => void;
}

const ItemSearch: React.FC<ItemSearchProps> = ({ onSelect }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [results, setResults] = useState<Item[]>([]);
  const [total, setTotal] = useState(0);
  const { searchItems, loading, error } = useSearchItems();

  const debouncedSearch = useCallback(
    debounce(async (term: string) => {
      if (term.length > 2) {
        const searchResults = await searchItems(term);
        setResults(searchResults.items);
        setTotal(searchResults.total);
      } else {
        setResults([]);
        setTotal(0);
      }
    }, 300),
    [],
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const term = e.target.value;
    setSearchTerm(term);
    debouncedSearch(term);
  };

  return (
    <div className="w-full max-w-sm space-y-2">
      <Input
        type="text"
        placeholder="Search items..."
        value={searchTerm}
        onChange={handleInputChange}
      />
      {loading && (
        <div className="flex items-center justify-center">
          <Loader2 className="h-6 w-6 animate-spin" />
        </div>
      )}
      {error && <p className="text-red-500">Error: {error}</p>}
      {!loading && results.length === 0 && searchTerm.length > 2 && (
        <p>No results found</p>
      )}
      {results.length > 0 && (
        <>
          <ul className="space-y-1">
            {results.map((item) => (
              <li key={item.id}>
                <Button
                  variant="ghost"
                  className="w-full justify-start"
                  onClick={() => onSelect(item)}
                >
                  {item.name}
                </Button>
              </li>
            ))}
          </ul>
          <p className="text-sm text-gray-500">
            Showing {results.length} of {total} results
          </p>
        </>
      )}
    </div>
  );
};

export default ItemSearch;
