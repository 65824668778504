import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PictureEditForm, { PictureEditFormData } from "./PictureEditForm";
import TagDisplay from "./TagDisplay.tsx";
import { Button } from "@/components/ui/button.tsx";
import { Card } from "@/components/ui/card.tsx";
import ConfirmDelete from "@/components/ConfirmDelete.tsx";
import CreateItemButton from "@/pictures/components/CreateItemButton.tsx";
import {
  fetchPictureRequest,
  updatePictureRequest,
  deletePictureRequest,
  describePictureRequest,
  selectPicture,
} from "./picturesSlice.ts";
import ItemSearch from "@/items/components/ItemSearch.tsx";
import { pictureUrl } from "@/pictures/utils.ts";
import BoxSearch from "@/boxes/components/BoxSearch.tsx";
import CreateBoxButton from "@/pictures/components/CreateBoxButton.tsx";
import { RootState } from "@/store.ts";
import { Picture } from "@/pictures/types.ts";

const PictureDetailPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const picture = useSelector<RootState, Picture | null>((state) =>
    selectPicture(state, id),
  );
  const [isEditing, setIsEditing] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState<string | null>(
    picture?.itemId || null,
  );
  const [selectedBoxId, setSelectedBoxId] = useState(picture?.boxId || null);

  useEffect(() => {
    if (id) {
      dispatch(fetchPictureRequest(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    setSelectedItemId(picture?.itemId || null);
  }, [picture?.itemId]);

  const handleEdit = () => setIsEditing(true);
  const handleCancelEdit = () => setIsEditing(false);

  const handleSubmit = (data: PictureEditFormData) => {
    if (id) {
      dispatch(updatePictureRequest({ id, ...data }));
      setIsEditing(false);
    }
  };

  const handleDelete = () => {
    if (id) {
      dispatch(deletePictureRequest(id));
      setShowDeleteModal(false);
      navigate("/pictures");
    }
  };

  const handleDescribePicture = () => {
    if (id) {
      dispatch(describePictureRequest(id));
    }
  };

  if (!picture) return <div>Loading...</div>;

  function handleUpdateItemId() {
    if (id && selectedItemId) {
      dispatch(
        updatePictureRequest({
          id,
          itemId: selectedItemId,
        }),
      );
    }
  }

  function handleUpdateBoxId() {
    if (id && selectedBoxId) {
      dispatch(
        updatePictureRequest({
          id,
          boxId: selectedBoxId,
        }),
      );
    }
  }

  if (!id) return null;

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Picture Details</h1>
      <Card>
        <img
          src={pictureUrl(picture)}
          alt={picture.name}
          className="w-full h-auto max-h-96 object-contain mb-4 rounded-t-lg"
        />
        {isEditing ? (
          <PictureEditForm picture={picture} onSubmit={handleSubmit} />
        ) : (
          <div className="p-5">
            <h2 className="text-xl font-semibold mb-2">{picture.name}</h2>
            <p className="text-gray-600 mb-4">{picture.description}</p>
            {picture.aiDescription ? (
              <p className="text-gray-600 mb-4">
                ✨ {picture.aiDescription} ✨
              </p>
            ) : (
              <Button onClick={handleDescribePicture}>Describe Picture</Button>
            )}
            <p className="text-sm text-gray-500 mb-4">
              Uploaded on: {new Date(picture.uploadDate).toLocaleDateString()}
            </p>
            <TagDisplay tags={picture.tags} onRemove={() => {}} />
            <div className="flex space-x-2">
              <Button color="light" onClick={handleEdit}>
                Edit
              </Button>
              <Button color="failure" onClick={() => setShowDeleteModal(true)}>
                Delete
              </Button>
            </div>
          </div>
        )}
      </Card>
      {isEditing && (
        <Button color="light" onClick={handleCancelEdit} className="mt-4">
          Cancel Edit
        </Button>
      )}
      <div className="mt-4">
        <Button color="gray" onClick={() => navigate("/pictures")}>
          Back to Pictures
        </Button>
      </div>

      <div className="mt-4">
        <ItemSearch onSelect={(item) => setSelectedItemId(item.id)} />
        {selectedItemId !== picture.itemId && (
          <Button onClick={handleUpdateItemId}>Update Attached Item</Button>
        )}
      </div>

      {picture.itemId ? (
        <div className="mt-4">
          <Button
            onClick={() => navigate(`/items/${picture.itemId}`)}
            color="light"
          >
            View Attached Item
          </Button>
        </div>
      ) : (
        <div className="mt-4">
          <CreateItemButton pictureId={id} />
        </div>
      )}

      <div className="mt-4">
        <BoxSearch onSelect={(box) => setSelectedBoxId(box.id)} />
        {selectedBoxId !== picture.boxId && (
          <Button onClick={handleUpdateBoxId}>Update Attached Box</Button>
        )}
      </div>

      {picture.boxId ? (
        <div className="mt-4">
          <Button
            onClick={() => navigate(`/boxes/${picture.boxId}`)}
            color="light"
          >
            View Attached Box
          </Button>
        </div>
      ) : (
        <div className="mt-4">
          <CreateBoxButton pictureId={id} />
        </div>
      )}

      <ConfirmDelete
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={handleDelete}
        itemName={"picture"}
      />
    </div>
  );
};

export default PictureDetailPage;
