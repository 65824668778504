import { Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table.tsx";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentPage,
  selectItemsPage,
  selectTotalPages,
  setCurrentPage,
  setSearchTerm,
} from "@/items/itemsSlice.ts";
import ItemsListRow from "@/items/components/ItemsListRow.tsx";
import Paginator from "@/components/Paginator.tsx";
import { Input } from "@/components/ui/input.tsx";

const ItemList = () => {
  const items = useSelector(selectItemsPage);
  const currentPage = useSelector(selectCurrentPage);
  const totalPages = useSelector(selectTotalPages);

  const dispatch = useDispatch();

  const handlePrevPage = () => {
    if (currentPage > 1) {
      dispatch(setCurrentPage(currentPage - 1));
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      dispatch(setCurrentPage(currentPage + 1));
    }
  };

  const handleSetSearch = (e: { target: { value: string } }) => {
    dispatch(setSearchTerm(e.target.value));
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className={"grid grid-cols-2"}>
        <Input
          type={"text"}
          placeholder={"Search items"}
          className={"w-full"}
          onChange={handleSetSearch}
        />
        <Paginator
          currentPage={currentPage}
          totalPages={totalPages}
          handlePrevPage={handlePrevPage}
          handleNextPage={handleNextPage}
        />
      </div>
      <Table className={"text-left"}>
        <TableHeader>
          <TableRow>
            <TableHead>Name</TableHead>
            <TableHead>Description</TableHead>
            <TableHead>Box</TableHead>
            <TableHead>Picture(s)?</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {items.map((item) => (
            <ItemsListRow id={item.id} key={item.id} />
          ))}
        </TableBody>
      </Table>
      {items.length === 0 && (
        <p className="text-center mt-4">No items found.</p>
      )}
      <Link to={"/items/new"} className="block mt-4 text-blue-500">
        Create a new item
      </Link>
    </div>
  );
};

export default ItemList;
