import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { searchBoxesStart, selectListingPage } from "./boxesSlice.ts";
import { Link } from "react-router-dom";
import { Button, buttonVariants } from "@/components/ui/button.tsx";

const BoxListingPage: React.FC = () => {
  const dispatch = useDispatch();
  const { boxes, loading, error, totalCount, currentPage, searchTerm } =
    useSelector(selectListingPage);
  const [localSearchTerm, setLocalSearchTerm] = useState(searchTerm);

  const itemsPerPage = 10; // Should match the limit in the saga
  const totalPages = Math.ceil(totalCount / itemsPerPage);

  useEffect(() => {
    dispatch(searchBoxesStart({ searchTerm, page: currentPage }));
  }, [dispatch, searchTerm, currentPage]);

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(searchBoxesStart({ searchTerm: localSearchTerm, page: 1 }));
  };

  const handlePageChange = (newPage: number) => {
    dispatch(searchBoxesStart({ searchTerm, page: newPage }));
  };

  if (error) return <div>Error: {error}</div>;

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Box Listing</h1>

      <form onSubmit={handleSearch} className="mb-4">
        <input
          type="text"
          value={localSearchTerm}
          onChange={(e) => setLocalSearchTerm(e.target.value)}
          placeholder="Search boxes..."
          className="border p-2 mr-2"
        />
        <Button type="submit">Search</Button>
      </form>

      <Link to={"/boxes/new"} className={buttonVariants()}>
        New Box
      </Link>

      {loading ? (
        <div>Loading...</div>
      ) : (
        <>
          <ul className="space-y-2">
            {boxes.map((box) => (
              <li key={box.id} className="border p-2 rounded">
                <h2 className="font-bold">
                  <Link to={`/boxes/${box.id}`}>{box.name}</Link>
                </h2>
                <p>{box.description}</p>
              </li>
            ))}
          </ul>

          <div className="mt-4 flex justify-between items-center">
            <Button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </Button>
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <Button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next
            </Button>
          </div>
          <Link
            to={"/boxes/new"}
            className={buttonVariants({ variant: "default" })}
          >
            New Box
          </Link>
        </>
      )}
    </div>
  );
};

export default BoxListingPage;
