import {
  createAction,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { Picture } from "@/pictures/types.ts";
import { keyBy } from "lodash";
import { RootState } from "@/store.ts";

export interface PicturesState {
  pictures: Record<string, Picture>;
  currentPage: number;
  selectedPictureId: string | null;
}

const initialState: PicturesState = {
  selectedPictureId: null,
  pictures: {},
  currentPage: 1,
};

export const picturesSlice = createSlice({
  name: "pictures",
  initialState,
  reducers: {
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    addPicture: (state, action: PayloadAction<Picture>) => {
      state.pictures[action.payload.id] = action.payload;
    },
    removePicture: (state, action: PayloadAction<string>) => {
      delete state.pictures[action.payload];
    },
    updatePicture: (
      state,
      action: PayloadAction<Partial<Picture> & { id: string }>,
    ) => {
      if (state.pictures[action.payload.id]) {
        state.pictures[action.payload.id] = {
          ...state.pictures[action.payload.id],
          ...action.payload,
        };
      }
    },
    setPicture: (state, action: PayloadAction<Picture>) => {
      state.pictures[action.payload.id] = action.payload;
    },
    setPictures: (state, action: PayloadAction<Picture[]>) => {
      state.pictures = keyBy(action.payload, "id");
    },
  },
});

export const fetchPicturesRequest = createAction<number>(
  "pictures/fetchPicturesRequest",
);

export const fetchPictureRequest = createAction<string>(
  "pictures/fetchPictureRequest",
);

export const describePictureRequest = createAction<string>(
  "pictures/describePicture",
);

export const updatePictureRequest = createAction<
  Partial<Picture> & { id: string }
>("pictures/updatePictureRequest");
export const deletePictureRequest = createAction<string>(
  "pictures/deletePictureRequest",
);

const thisSlice = (state: RootState) => state.pictures;

export const selectPicture = createSelector(
  thisSlice,
  (_state: RootState, id?: string) => id,
  (state, id?: string) => (id ? state.pictures[id] : null),
);

export const selectAllPictures = (state: RootState) =>
  thisSlice(state).pictures;

export const selectCurrentPage = (state: RootState) =>
  thisSlice(state).currentPage;

export const selectTotalPages = createSelector(selectAllPictures, (pictures) =>
  Math.ceil(Object.values(pictures).length / 12),
);

export const selectPicturesPage = createSelector(
  selectAllPictures,
  selectCurrentPage,
  (pictures, currentPage) => {
    const picturesArray = Object.values(pictures);
    picturesArray.sort((a, b) => {
      return (
        new Date(b.uploadDate).getTime() - new Date(a.uploadDate).getTime()
      );
    });
    return picturesArray.slice((currentPage - 1) * 12, currentPage * 12);
  },
);

export const selectPicturesByTags = createSelector(
  selectAllPictures,
  (_state: RootState, tag_ids: string[]) => tag_ids,
  (pictures, tag_ids) => {
    return Object.values(pictures).filter((picture) =>
      picture.tags.some((tag) => tag_ids.includes(tag.id)),
    );
  },
);

export const selectUnattachedPictures = createSelector(
  selectAllPictures,
  (pictures) => {
    return Object.values(pictures).filter(
      (picture) => !picture.itemId && !picture.boxId,
    );
  },
);

export const {
  setCurrentPage,
  addPicture,
  removePicture,
  updatePicture,
  setPictures,
  setPicture,
} = picturesSlice.actions;
export default picturesSlice.reducer;
